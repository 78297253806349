import { useIntl } from 'react-intl';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import TextField from '@cimpress/react-components/lib/TextField';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { info } from '@cimpress/react-components/lib/colors';

import { compactCommaSeparatedString } from '../../helpers/compactCommaSeparatedString';
import { Action, editPackCalculator } from '../../reducers/editActions';
import { BIGGEST_PACKS } from '../../reducers/editConstants';
import { PackCalculatorState, SelectField } from '../shared/types';
import PreferredPackQuantitySelect from './PreferredPackQuantitySelect';
import messages from './messages';

type PackQuantitiesInputsProps = {
  mappingId: string;
  packCalculator: PackCalculatorState;
  resetEvaluation: () => void;
  dispatch: React.Dispatch<Action>;
};

const PackQuantitiesInputs = ({ mappingId, packCalculator, resetEvaluation, dispatch }: PackQuantitiesInputsProps) => {
  const { formatMessage } = useIntl();

  // Strip extra commas and spaces from input
  const onBlurQuantitiesInput = () => {
    const packableQuantities = packCalculator.packableQuantities ?? '';
    const normalizedQuantities = compactCommaSeparatedString(packableQuantities).toString();
    dispatch(editPackCalculator({ mappingId, field: 'packableQuantities', value: normalizedQuantities }));
  };

  const preferredPackQuantityOptions = [{ label: formatMessage(messages.noPreferredPackQuantityOption), value: '' }];
  if (packCalculator.packableQuantities !== undefined) {
    const packableQuantities = packCalculator.packableQuantities
      .split(',')
      .filter(quantity => quantity !== '')
      .map(quantity => ({ label: quantity, value: quantity }));

    preferredPackQuantityOptions.push(...packableQuantities);
  }

  const onChangeQuantities = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editPackCalculator({ mappingId, field: name, value: value }));

    // If the preferred pack quantity value was removed from the set of quantities, reset preferred pack quantity.
    const quantities = value.split(',').filter(quantity => quantity !== '');
    const quantitiesHasPreferredPackQuantity = Boolean(
      quantities.find(quantity => quantity === packCalculator.preferredPackQuantity),
    );
    if (!quantitiesHasPreferredPackQuantity) {
      dispatch(editPackCalculator({ mappingId, field: 'preferredPackQuantity', value: '' }));
    }

    resetEvaluation();
  };

  const onChangePackCalculator = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editPackCalculator({ mappingId, field: name, value }));
    resetEvaluation();
  };

  const onChangePreferredPackQuantitySelection = (selection: SelectField) => {
    dispatch(editPackCalculator({ mappingId, field: 'preferredPackQuantity', value: selection.value }));
    resetEvaluation();
  };

  return (
    <div>
      {packCalculator.shreddingStrategy === BIGGEST_PACKS ? (
        <TextField
          name="packableQuantities"
          label={formatMessage(messages.quantities)}
          value={packCalculator.packableQuantities}
          onChange={onChangeQuantities}
          onBlur={onBlurQuantitiesInput}
          required
        />
      ) : (
        <TextField
          name="maxQuantity"
          label={formatMessage(messages.maxQuantity)}
          value={packCalculator.maxQuantity}
          onChange={onChangePackCalculator}
          required
          type="number"
        />
      )}
      {packCalculator.shreddingStrategy === BIGGEST_PACKS ? (
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px', width: '95%' }}>
            <PreferredPackQuantitySelect
              value={packCalculator.preferredPackQuantity}
              options={preferredPackQuantityOptions}
              onChange={onChangePreferredPackQuantitySelection}
              disabled={preferredPackQuantityOptions.length === 1}
            />
          </div>
          <Tooltip contents={formatMessage(messages.preferredPackQuantityTooltip)}>
            <IconInformationCircle size="2x" style={{ margin: '10px 0 0 0' }} weight="fill" color={info.base} />
          </Tooltip>
        </div>
      ) : undefined}
    </div>
  );
};

export default PackQuantitiesInputs;
