import { useQuery, UseQueryOptions } from 'react-query';

import { ServiceHttpResponseError } from '../services/serviceHelpers';
import { getPackCalculator } from '../services/shippablePackDetails';
import { PackCalculator } from '../services/types';

const usePackCalculator = ({
  id,
  ...rest
}: { id: string | undefined } & UseQueryOptions<PackCalculator, ServiceHttpResponseError, PackCalculator, any[]>) => {
  return useQuery(['packCalculator', id], () => getPackCalculator(id!), {
    ...rest,
    enabled: Boolean(id),
  });
};

export default usePackCalculator;
