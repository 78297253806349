/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useIntl } from 'react-intl';

import SkuAttributeModelBuilder from '@cimpress-technology/sku-attribute-model-builder';
import { Radio, RadioGroup } from '@cimpress/react-components';
import TextField from '@cimpress/react-components/lib/TextField';

import auth from '../../auth';
import usePackCalculator from '../../hooks/usePackCalculator';
import { BIGGEST_PACKS, EVEN_PACKS } from '../../reducers/editConstants';
import Loading from '../Loading';
import ErrorDisplay from '../shared/ErrorDisplay';
import FormulaDisplay from './FormulaDisplay';
import MappingProps from './mappingProps';
import messages from './messages';

const packBodyStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
`;

const PackMappingViewer = ({ mapping }: { mapping: MappingProps }) => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';
  const packCalculatorId = mapping?._embedded.packCalculator.id;
  const packCalculatorQuery = usePackCalculator({ id: packCalculatorId });
  const { formatMessage } = useIntl();

  if (packCalculatorQuery.isError) {
    return (
      <div style={{ margin: 'auto', marginTop: '30px' }}>
        <hr />
        <ErrorDisplay errorMsg={formatMessage(messages.errorMessage)} />
      </div>
    );
  }

  if (packCalculatorQuery.isLoading || packCalculatorQuery.isIdle) {
    return <Loading />;
  }

  const packCalculatorData = packCalculatorQuery.data;
  // TODO: look into display issues in firefox

  return (
    <div>
      <hr />
      <h2 style={{ paddingBottom: 10 }}>{packCalculatorData.name}</h2>
      <div css={packBodyStyle}>
        <div>
          <h4>{formatMessage(messages.packFormulas)}</h4>
          <RadioGroup
            inline
            name="shreddingStrategy"
            valueSelected={
              packCalculatorData.shreddingStrategy ??
              (packCalculatorData.packableQuantities ? BIGGEST_PACKS : EVEN_PACKS)
            }
          >
            <Radio label={formatMessage(messages.useBiggestPacks)} value={BIGGEST_PACKS} disabled />
            <Radio label={formatMessage(messages.dividePacksEvenly)} value={EVEN_PACKS} disabled />
          </RadioGroup>
          {Boolean(packCalculatorData.packableQuantities) && (
            <FormulaDisplay
              label={formatMessage(messages.quantities)}
              value={packCalculatorData.packableQuantities?.join(', ')}
            />
          )}
          {Boolean(packCalculatorData.maxQuantity) && (
            <FormulaDisplay
              label={formatMessage(messages.maxQuantity)}
              value={String(packCalculatorData.maxQuantity)}
            />
          )}
          <FormulaDisplay
            label={formatMessage(messages.preferredPackQuantity)}
            value={String(packCalculatorData.preferredPackQuantity ?? '')}
          />
          <FormulaDisplay
            label={formatMessage(messages.packsPerContainer)}
            value={packCalculatorData.packsPerContainer}
          />
          <FormulaDisplay
            label={formatMessage(messages.weightFormula)}
            value={packCalculatorData.weight?.value}
            unit={packCalculatorData.weight?.unit}
          />
          <FormulaDisplay
            label={formatMessage(messages.preferredContainerType)}
            value={packCalculatorData.preferredContainerType}
          />
          <FormulaDisplay
            label={formatMessage(messages.lengthFormula)}
            value={packCalculatorData.length?.value}
            unit={packCalculatorData.length?.unit}
          />
          <FormulaDisplay
            label={formatMessage(messages.widthFormula)}
            value={packCalculatorData.width?.value}
            unit={packCalculatorData.width?.unit}
          />
          <FormulaDisplay
            label={formatMessage(messages.heightFormula)}
            value={packCalculatorData.height?.value}
            unit={packCalculatorData.height?.unit}
          />
          <FormulaDisplay
            label={formatMessage(messages.diameterFormula)}
            value={packCalculatorData.diameter?.value}
            unit={packCalculatorData.diameter?.unit}
          />
          <FormulaDisplay
            label={formatMessage(messages.volumeFormula)}
            value={packCalculatorData.volume?.value}
            unit={packCalculatorData.volume?.unit}
          />
        </div>
        <div>
          <h4>Product Info</h4>
          {mapping.skus && (
            <div>
              <h6>{formatMessage(messages.skus)}</h6>
              <TextField value={mapping.skus.join(', ')} readOnly onChange={val => val} />
            </div>
          )}
          {mapping._links?.attributeModel?.href && (
            <>
              <h6>{formatMessage(messages.attributeModel)}</h6>
              <SkuAttributeModelBuilder
                token={auth.getAccessToken()}
                resourceUrl={mapping._links.attributeModel.href}
                isReadOnly
                isProduction={isProduction}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackMappingViewer;
