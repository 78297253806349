import { defineMessages } from 'react-intl';

export default defineMessages({
  createPacks: {
    id: 'createPage.createPacks',
    defaultMessage: 'Create Packs',
  },
  managePacks: {
    id: 'createPage.managePacks',
    defaultMessage: 'Manage Packs',
  },
  cancel: {
    id: 'createPage.cancel',
    defaultMessage: 'Cancel',
  },
  name: {
    id: 'createPage.name',
    defaultMessage: 'Name',
  },
  addFormula: {
    id: 'createPage.addFormula',
    defaultMessage: 'Add Formula',
  },
  test: {
    id: 'createPage.test',
    defaultMessage: 'Test',
  },
  save: {
    id: 'createPage.save',
    defaultMessage: 'Save',
  },
  saving: {
    id: 'createPage.saving',
    defaultMessage: 'Saving',
  },
  loading: {
    id: 'createPage.loading',
    defaultMessage: 'Loading...',
  },
  accountSelect: {
    id: 'createPage.accountSelect',
    defaultMessage: 'Select an Account',
  },
  accountFetchFailed: {
    id: 'createPage.accountFetchFailed',
    defaultMessage: 'Failed to load accounts.',
  },
  attributeModelFetchFailed: {
    id: 'createPage.attributeModelFetchFailed',
    defaultMessage:
      'There was an error retrieving the existing attribute model for this pack. Please try refreshing the page to avoid losing your data.',
  },
  lengthFormula: {
    id: 'createPage.lengthFormula',
    defaultMessage: 'Length Formula',
  },
  widthFormula: {
    id: 'createPage.widthFormula',
    defaultMessage: 'Width Formula',
  },
  heightFormula: {
    id: 'createPage.heightFormula',
    defaultMessage: 'Height Formula',
  },
  diameterFormula: {
    id: 'createPage.diameterFormula',
    defaultMessage: 'Diameter Formula',
  },
  weightFormula: {
    id: 'createPage.weightFormula',
    defaultMessage: 'Weight Formula',
  },
  unit: {
    id: 'createPage.unit',
    defaultMessage: 'Unit',
  },
  preferredContainerType: {
    id: 'createPage.preferredContainerType',
    defaultMessage: 'Preferred Container Type',
  },
  box: {
    id: 'createPage.box',
    defaultMessage: 'Box',
  },
  tube: {
    id: 'createPage.tube',
    defaultMessage: 'Tube',
  },
  envelope: {
    id: 'createPage.envelope',
    defaultMessage: 'Envelope',
  },
  pallet: {
    id: 'createPage.pallet',
    defaultMessage: 'Pallet',
  },
  quantities: {
    id: 'createPage.quantities',
    defaultMessage: 'Quantities',
  },
  maxQuantity: {
    id: 'createPage.maxQuantity',
    defaultMessage: 'Maximum Quantity',
  },
  useBiggestPacks: {
    id: 'createPage.useBiggestPacks',
    defaultMessage: 'Use Biggest Packs',
  },
  dividePacksEvenly: {
    id: 'createPage.dividePacksEvenly',
    defaultMessage: 'Divide Packs Evenly',
  },
  numberOfPacks: {
    id: 'createPage.numberOfPacks',
    defaultMessage: '# of Packs Per Container',
  },
  commaSeparatedSkus: {
    id: 'createPage.commaSeparatedSkus',
    defaultMessage: 'Comma Separated Skus',
  },
  skuTooltip: {
    id: 'createPage.skuTooltip',
    defaultMessage: 'Either SKUs or published attributes are required.',
  },
  packsPerContainerTooltip: {
    id: 'createPage.packsPerContainerTooltip',
    defaultMessage:
      'Pre-determined number of packs that should go into the outer container. Typically not recommended to be configured.',
  },
  errorMessage: {
    id: 'createPage.errorMessage',
    defaultMessage: 'Error retrieving pack information.',
  },
  usedInFCWarning: {
    id: 'createPage.usedInFCWarning',
    defaultMessage:
      'This pack mapping set is currently being used in at least one fulfillment configuration. Any changes you make will impact new orders using these fulfillment configurations',
  },
  preferredPackQuantity: {
    id: 'createPage.preferredPackQuantity',
    defaultMessage: 'Preferred Pack Quantity',
  },
  preferredPackQuantityTooltip: {
    id: 'createPage.preferredPackQuantityTooltip',
    defaultMessage:
      'Optionally, if there are multiple possible ways of arranging packs, favor packs with this quantity.',
  },
  noPreferredPackQuantityOption: {
    id: 'createPage.noPreferredPackQuantityOption',
    defaultMessage: 'No Preferred Pack Quantity',
  },
  navigateToFcUI: {
    id: 'createPage.navigateToFcUI',
    defaultMessage: 'Return to Fulfillment Configurations UI',
  },
});
