import { map as pMap } from 'bluebird';
import buildUrl from 'build-url';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { MappingSetState } from '../components/shared/types';
import {
  createMappingSetRequestFromState,
  PackCalculatorRequest,
  TestPackCalculatorMappingSet,
} from '../helpers/createMappingSetRequestFromState';
import { fetchWithAuth } from './fetchWithAuth';
import { ServiceHttpResponseError } from './serviceHelpers';
import { MappingSet, PackCalculator, PackTestEvaluationResponse } from './types';

const endpointUrl = process.env.REACT_APP_SHIPPABLE_PACK_DETAILS as string;

type CalculatorLinks = { packCalculator: { href: string }; attributeModel?: { href: string } };

export const getPackMappingSets = async (
  accounts: string[] = [],
  creators: string[] = [],
  limit: number = 200,
  offset: number = 0,
): Promise<{ mappingSets: MappingSet[]; hasNext: boolean; total: number }> => {
  const queryParams: any = {
    accountId: accounts.length ? accounts : undefined,
    createdBy: creators.length ? creators : undefined,
    limit,
    offset,
    sort: 'createdAt:desc',
  };

  const route = buildUrl('v2/packCalculatorMappingSets', {
    queryParams: queryParams,
    disableCSV: true,
  });

  const response = await fetchWithAuth({ endpointUrl, route });
  const mappingSets = get(response, '_embedded.item', []);
  const hasNext = !isNil(get(response, '_links.next'));

  return { mappingSets, hasNext, total: response.total };
};

export const getPackMappingSet = async (id: string): Promise<MappingSet> => {
  const result = await fetchWithAuth({
    endpointUrl,
    route: `v2/packCalculatorMappingSets/${id}`,
    giveSimpleResponse: false,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
  });
  const etag = result.response.headers.get('ETag');
  return { ...result.body, etag };
};

export const deletePackMappingSet = async ({ id, etag }: { id: string; etag: string }): Promise<void> => {
  await fetchWithAuth({
    endpointUrl,
    method: 'DELETE',
    route: `v2/packCalculatorMappingSets/${id}`,
    additionalHeaders: { 'If-Match': etag },
  });
};

export const getPackCalculator = async (id: string): Promise<PackCalculator> => {
  const result = await fetchWithAuth({
    endpointUrl,
    route: `v2/packCalculators/${id}`,
    giveSimpleResponse: false,
    additionalHeaders: { 'Cache-Control': 'no-cache' },
  });
  const etag = result.response.headers.get('ETag');
  return { ...result.body, etag };
};

export const createPackMappingSet = async ({
  accountId,
  mappingSetState,
  groupId,
}: {
  accountId: string;
  mappingSetState: MappingSetState;
  groupId?: string;
}) => {
  const packMappingSet = createMappingSetRequestFromState({
    accountId,
    mappingSetState,
  });
  const { name, mappings } = packMappingSet;

  const mappingsWithSavedCalculators = await pMap(
    mappings,
    async mapping => {
      const { skus, packCalculator } = mapping;

      const response = await createPackCalculator({ ...packCalculator, coamGroupId: groupId });
      const packCalculatorLink = response._links.version.href;

      const _links: CalculatorLinks = {
        packCalculator: { href: packCalculatorLink },
      };
      if (mapping.attributeModelUrl) {
        _links.attributeModel = { href: mapping.attributeModelUrl };
      }

      return { skus, _links };
    },
    { concurrency: 8 },
  );

  return await fetchWithAuth({
    endpointUrl,
    route: 'v2/packCalculatorMappingSets',
    method: 'POST',
    body: {
      name,
      accountId,
      mappings: mappingsWithSavedCalculators,
      coamGroupId: groupId,
    },
  });
};

export const updatePackMappingSet = async ({
  accountId,
  packMappingSetId,
  mappingSetState,
  groupId,
}: {
  accountId: string;
  packMappingSetId: string;
  mappingSetState: MappingSetState;
  groupId?: string;
}) => {
  const packMappingSet = createMappingSetRequestFromState({
    accountId,
    mappingSetState,
  });
  const { name, mappings, etag } = packMappingSet;

  const mappingsWithSavedCalculators = await pMap(
    mappings,
    async mapping => {
      const { skus, packCalculator } = mapping;

      let response;
      if (mapping.packCalculatorId) {
        response = await updatePackCalculator({ packCalculatorId: mapping.packCalculatorId, packCalculator });
      } else {
        response = await createPackCalculator({ ...packCalculator, coamGroupId: groupId });
      }

      const packCalculatorLink = response._links.version.href;

      const _links: CalculatorLinks = {
        packCalculator: { href: packCalculatorLink },
      };
      if (mapping.attributeModelUrl) {
        _links.attributeModel = { href: mapping.attributeModelUrl };
      }

      return { skus, _links };
    },
    { concurrency: 8 },
  );

  return await fetchWithAuth({
    endpointUrl,
    route: `v2/packCalculatorMappingSets/${packMappingSetId}`,
    method: 'PUT',
    body: {
      name,
      mappings: mappingsWithSavedCalculators,
    },
    additionalHeaders: { 'If-Match': etag },
  });
};

export const createPackCalculator = async (packCalculator: PackCalculatorRequest) => {
  return await fetchWithAuth({
    endpointUrl,
    route: 'v2/packCalculators',
    method: 'POST',
    body: packCalculator,
  });
};

export const updatePackCalculator = async ({
  packCalculatorId,
  packCalculator,
}: {
  packCalculatorId: string;
  packCalculator: PackCalculatorRequest;
}) => {
  return await fetchWithAuth({
    endpointUrl,
    route: `v2/packCalculators/${packCalculatorId}`,
    method: 'PUT',
    body: packCalculator,
    additionalHeaders: { 'If-Match': packCalculator.etag },
  });
};

export const testEvaluate = async ({
  deliverableQuantity,
  productConfigurationUrl,
  packCalculatorMappingSet,
}: {
  deliverableQuantity: number;
  productConfigurationUrl: string;
  packCalculatorMappingSet: TestPackCalculatorMappingSet;
}): Promise<PackTestEvaluationResponse | undefined> => {
  try {
    const data = await fetchWithAuth({
      endpointUrl,
      route: 'v2/packCalculatorMappingSets:testEvaluate',
      method: 'POST',
      body: { deliverableQuantity, productConfigurationUrl, packCalculatorMappingSet },
    });
    return data;
  } catch (error: any) {
    if (error instanceof ServiceHttpResponseError) {
      // Error messages are very deeply nested and difficult to access
      const errorMessages = get(error, 'response.body.details.evaluationErrors').flatMap(
        (evaluationErrors: { errors: { message: string }[] }) => evaluationErrors.errors,
      );
      throw errorMessages;
    }
  }
};
