/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import omit from 'lodash/omit';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import SkuAttributeModelBuilder from '@cimpress-technology/sku-attribute-model-builder';
import { Alert, Accordion, Radio, RadioGroup } from '@cimpress/react-components';
import Button from '@cimpress/react-components/lib/Button';
import InlineEdit from '@cimpress/react-components/lib/InlineEdit';
import TextField from '@cimpress/react-components/lib/TextField';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { danger } from '@cimpress/react-components/lib/colors';
import { info, success } from '@cimpress/react-components/lib/colors';

import auth from '../../auth';
import { compactCommaSeparatedString } from '../../helpers/compactCommaSeparatedString';
import { createStateFromPackCalculator } from '../../helpers/createStateFromMappingSet';
import useAttributeModel from '../../hooks/useAttributeModel';
import usePackCalculator from '../../hooks/usePackCalculator';
import {
  Action,
  editMapping,
  editPackCalculator,
  rehydrateAttributeModel,
  rehydratePackCalculator,
  removePackMapping,
} from '../../reducers/editActions';
import { BIGGEST_PACKS, EVEN_PACKS } from '../../reducers/editConstants';
import { PackTestEvaluationResponse, ShreddingStrategy } from '../../services/types';
import Loading from '../Loading';
import ErrorDisplay from '../shared/ErrorDisplay';
import { MappingState, SelectField } from '../shared/types';
import DimensionInputs from './DimensionInputs';
import PackQuantitiesInputs from './PackQuantitiesInputs';
import WeightUnitSelect from './WeightUnitSelect';
import messages from './messages';

const accessToken = auth.getAccessToken();
const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

const skuAttributeDisplay = css`
  position: relative;

  .sku-preview,
  form,
  span.label {
    display: none;
  }

  max-width: 700px;
`;

export type AttributeModelRef = {
  publish: () => Promise<any>;
};

const evaluationGrid = css`
  margin: 0 0 15px 15px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(auto-fill, 50px);
  grid-auto-columns: max-content;
  column-gap: 15px;
  row-gap: 15px;
  align-items: center;
`;

type PackCalculatorMappingProps = {
  dispatch: React.Dispatch<Action>;
  mapping: MappingState;
  setRefs: React.Dispatch<React.SetStateAction<Record<string, AttributeModelRef | null>>>;
  id: string;
  evaluationResult?: PackTestEvaluationResponse;
  resetEvaluation: () => void;
};

const PackCalculatorMapping = ({
  dispatch,
  mapping,
  evaluationResult,
  resetEvaluation,
  setRefs,
  id,
}: PackCalculatorMappingProps) => {
  const { packCalculator, id: mappingId } = mapping;
  const { formatMessage } = useIntl();

  const [commaSeparatedSkus, setCommaSeparatedSkus] = useState<string>(mapping.skus.join(','));
  const [weightUnitSelection, setWeightUnitSelection] = useState<SelectField>();

  const [selectorRef, setSelectorRef] = useState<AttributeModelRef | null>(null);

  // Don't use an actual ref, but a callback ref so we can actually know when the "ref" mutates.
  // Even though this is a stable callback, we still get the value --> null --> value as a ref;
  // not sure why though, possibly due to how the attribute selector is handling the ref, see: https://github.com/facebook/react/issues/9328#issuecomment-298438237
  const skuAttributeSelectorRef = useCallback(
    ref => {
      // Don't call setState if we already have a ref.
      // This would cause an infinite loop which is why we can't pass `setSelectorRef` directly as the "ref".
      // Have to do an extra null check for the incoming ref due to stated issue above.
      if (ref != null && selectorRef == null) {
        setSelectorRef(ref);
      }
    },
    [selectorRef],
  );

  // Keep track of all the refs from the attribute model component.
  // When this PackCalculatorMapping component gets unmounted, remove the ref from state so it doesn't get published
  useEffect(() => {
    setRefs((refs: any) => ({ ...refs, [id]: selectorRef }));
    return () => {
      setRefs(refs => omit(refs, id));
    };
  }, [id, setRefs, selectorRef]);

  const packCalculatorQuery = usePackCalculator({
    id: mapping.retrievedPackCalculatorId,
    onSuccess: data => {
      dispatch(rehydratePackCalculator(createStateFromPackCalculator(data)));
    },
  });

  const attributeModelQuery = useAttributeModel({
    attributeModelUrl: mapping.attributeModelUrl,
    onSuccess: (data: any) => dispatch(rehydrateAttributeModel({ mappingId, data })),
  });

  const onChangePackCalculator = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editPackCalculator({ mappingId, field: name, value }));
    resetEvaluation();
  };

  // Strip extra commas and spaces from input
  const onBlurSkuInput = () => setCommaSeparatedSkus(compactCommaSeparatedString(commaSeparatedSkus).toString());

  const onChangeSkus = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const skus = compactCommaSeparatedString(value);
    setCommaSeparatedSkus(value);
    dispatch(editMapping({ mappingId, field: name, value: skus }));
    resetEvaluation();
  };

  const onChangeWeightUnit = (selection: SelectField) => {
    setWeightUnitSelection(selection);
    dispatch(editPackCalculator({ mappingId, field: 'weightUnit', value: selection.value }));
    resetEvaluation();
  };

  const onDeleteCalculator = () => {
    dispatch(removePackMapping({ mappingId }));
    resetEvaluation();
  };

  const onChangeShreddingStrategy = (_: any, val: ShreddingStrategy) => {
    if (val === BIGGEST_PACKS) {
      dispatch(editPackCalculator({ mappingId, field: 'maxQuantity', value: '' }));
    } else {
      dispatch(editPackCalculator({ mappingId, field: 'packableQuantities', value: '' }));
      dispatch(editPackCalculator({ mappingId, field: 'preferredPackQuantity', value: '' }));
    }
    dispatch(editPackCalculator({ mappingId, field: 'shreddingStrategy', value: val }));
    resetEvaluation();
  };

  if (packCalculatorQuery.isError) {
    return (
      <div style={{ margin: 'auto', marginTop: '30px' }}>
        <hr />
        <ErrorDisplay errorMsg={formatMessage(messages.errorMessage)} />
      </div>
    );
  }

  if (packCalculatorQuery.isLoading || attributeModelQuery.isLoading) {
    return <Loading />;
  }

  const header = (
    <div style={{ margin: '-15px 0' }}>
      <InlineEdit
        size="h3"
        name="name"
        value={mapping.packCalculator.name}
        onKeyDownCapture={e => e.key === ' ' && e.stopPropagation()}
        onClickCapture={e => e.stopPropagation()}
        onChange={onChangePackCalculator}
      />
    </div>
  );

  return (
    <Accordion
      style={{ marginBottom: '10px' }}
      bodyStyle={{ padding: '16px' }}
      title={header}
      actions={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {Boolean(evaluationResult) && (
            <IconCheckCircleAlt
              weight="fill"
              size="lg"
              style={{ color: success.base, marginLeft: 'auto', marginRight: '5px' }}
            />
          )}
          {attributeModelQuery.isError && <IconAlertTriangle weight="fill" size="lg" style={{ color: danger.base }} />}
          <Button onClick={onDeleteCalculator} variant="link" icon={<IconBin size="lg" />} />
        </div>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div css={skuAttributeDisplay}>
          <div style={{ display: 'flex' }}>
            <TextField
              style={{ flex: 1 }}
              name="skus"
              label={formatMessage(messages.commaSeparatedSkus)}
              value={commaSeparatedSkus}
              onChange={onChangeSkus}
              onBlur={onBlurSkuInput}
            />
            <Tooltip contents={formatMessage(messages.skuTooltip)}>
              <IconInformationCircle size="2x" style={{ margin: '10px 0 0 10px' }} weight="fill" color={info.base} />
            </Tooltip>
          </div>
          {attributeModelQuery.isError && (
            <Alert status="danger" message={formatMessage(messages.attributeModelFetchFailed)} />
          )}
          <SkuAttributeModelBuilder
            token={accessToken}
            mcpSkus={mapping.skus}
            isProduction={environment === 'Production'}
            ref={skuAttributeSelectorRef}
            showPublishBtn={false}
            attributeModel={mapping.attributeModel}
          />
        </div>
        <h4>Pack Information</h4>
        <RadioGroup
          onChange={onChangeShreddingStrategy}
          inline
          defaultSelected={BIGGEST_PACKS}
          name="shreddingStrategy"
          valueSelected={packCalculator.shreddingStrategy}
        >
          <Radio label={formatMessage(messages.useBiggestPacks)} value={BIGGEST_PACKS} />
          <Radio label={formatMessage(messages.dividePacksEvenly)} value={EVEN_PACKS} />
        </RadioGroup>
        <div style={{ display: 'flex', marginTop: '5px' }}>
          <div style={{ width: '700px' }}>
            <PackQuantitiesInputs
              mappingId={mappingId}
              packCalculator={packCalculator}
              resetEvaluation={resetEvaluation}
              dispatch={dispatch}
            />
            <div style={{ display: 'flex' }}>
              <TextField
                style={{ marginRight: '10px', width: '95%' }}
                name="packsPerContainer"
                label={formatMessage(messages.numberOfPacks)}
                type="number"
                value={packCalculator.packsPerContainer}
                onChange={onChangePackCalculator}
              />
              <Tooltip contents={formatMessage(messages.packsPerContainerTooltip)}>
                <IconInformationCircle size="2x" style={{ margin: '10px 0 0 0' }} weight="fill" color={info.base} />
              </Tooltip>
            </div>
            <div style={{ display: 'flex' }}>
              <TextField
                style={{ marginRight: '10px', width: '80%' }}
                name="weightFormula"
                label={formatMessage(messages.weightFormula)}
                value={packCalculator.weightFormula}
                onChange={onChangePackCalculator}
              />
              <div style={{ width: '20%' }}>
                <WeightUnitSelect
                  defaultWeight={packCalculator.weightUnit}
                  value={weightUnitSelection}
                  onChange={onChangeWeightUnit}
                />
              </div>
            </div>
            <DimensionInputs dispatch={dispatch} mapping={mapping} resetEvaluation={resetEvaluation} />
          </div>
          {evaluationResult &&
            evaluationResult._embedded.item[0].selectedPacks.map((pack, i) => (
              <div key={i} css={evaluationGrid}>
                <div style={{ gridRowStart: '1' }}>
                  {pack.quantity} packs of {pack._embedded.pack.quantity} each
                </div>
                <div style={{ gridRowStart: `${packCalculator.shreddingStrategy === EVEN_PACKS ? '3' : '4'}` }}>
                  {pack._embedded.pack.weight}
                </div>
                {pack._embedded.pack.preferredContainerType === 'tube' ? (
                  <>
                    <div style={{ gridRowStart: `${packCalculator.shreddingStrategy === EVEN_PACKS ? '5' : '6'}` }}>
                      {pack._embedded.pack.height}
                    </div>
                    <div>{pack._embedded.pack.diameter}</div>
                  </>
                ) : (
                  <>
                    <div style={{ gridRowStart: `${packCalculator.shreddingStrategy === EVEN_PACKS ? '5' : '6'}` }}>
                      {pack._embedded.pack.length}
                    </div>
                    <div style={{ gridRowStart: `${packCalculator.shreddingStrategy === EVEN_PACKS ? '6' : '7'}` }}>
                      {pack._embedded.pack.width}
                    </div>
                    <div style={{ gridRowStart: `${packCalculator.shreddingStrategy === EVEN_PACKS ? '7' : '8'}` }}>
                      {pack._embedded.pack.height}
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </Accordion>
  );
};

export default PackCalculatorMapping;
