import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import TextField from '@cimpress/react-components/lib/TextField';

import { editPackCalculator } from '../../reducers/editActions';
import DimensionUnitSelect from './DimensionUnitSelect';
import PackContainerSelect from './PackContainerSelect';
import messages from './messages';

const DimensionInputs = ({ dispatch, mapping, resetEvaluation }) => {
  const { packCalculator } = mapping;

  const { formatMessage } = useIntl();

  const [packContainerSelection, setPackContainerSelection] = useState();
  const [dimensionUnitSelection, setDimensionUnitSelection] = useState();

  const onChangePackCalculator = ({ target: { name, value } }) => {
    dispatch(editPackCalculator({ mappingId: mapping.id, field: name, value }));
    resetEvaluation();
  };

  const onChangeContainerSelection = selection => {
    setPackContainerSelection(selection);
    dispatch(editPackCalculator({ mappingId: mapping.id, field: 'preferredContainerType', value: selection.value }));
    resetEvaluation();
  };

  const onChangeDimension = selection => {
    setDimensionUnitSelection(selection);
    dispatch(editPackCalculator({ mappingId: mapping.id, field: 'dimensionUnit', value: selection.value }));
    resetEvaluation();
  };

  const boxInputs = (
    <>
      <div style={{ display: 'flex' }}>
        <TextField
          style={{ marginRight: '10px', width: '80%' }}
          name="lengthFormula"
          label={formatMessage(messages.lengthFormula)}
          value={packCalculator.lengthFormula}
          onChange={onChangePackCalculator}
        />
        <div style={{ width: '20%' }}>
          <DimensionUnitSelect
            defaultUnit={packCalculator.dimensionUnit}
            value={dimensionUnitSelection}
            onChange={onChangeDimension}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
      <TextField
        name="widthFormula"
        label={formatMessage(messages.widthFormula)}
        value={packCalculator.widthFormula}
        onChange={onChangePackCalculator}
      />
      <TextField
        name="heightFormula"
        label={formatMessage(messages.heightFormula)}
        value={packCalculator.heightFormula}
        onChange={onChangePackCalculator}
      />
    </>
  );

  const tubeInputs = (
    <>
      <div style={{ display: 'flex' }}>
        <TextField
          style={{ marginRight: '10px', width: '80%' }}
          name="heightFormula"
          label={formatMessage(messages.heightFormula)}
          value={packCalculator.heightFormula}
          onChange={onChangePackCalculator}
        />
        <div style={{ width: '20%' }}>
          <DimensionUnitSelect
            defaultUnit={packCalculator.dimensionUnit}
            value={dimensionUnitSelection}
            onChange={onChangeDimension}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
      <TextField
        name="diameterFormula"
        label={formatMessage(messages.diameterFormula)}
        value={packCalculator.diameterFormula}
        onChange={onChangePackCalculator}
      />
    </>
  );

  return (
    <div>
      <div style={{ width: '30%' }}>
        <PackContainerSelect
          defaultContainer={packCalculator.preferredContainerType}
          value={packContainerSelection}
          onChange={onChangeContainerSelection}
          menuPortalTarget={document.body}
        />
      </div>
      {packCalculator.preferredContainerType === 'tube' ? tubeInputs : boxInputs}
    </div>
  );
};

export default DimensionInputs;
