import map from 'lodash/map';

import { MappingSetState, PackCalculatorState } from '../components/shared/types';
import {
  BIGGEST_PACKS,
  createInitialPackMappingSetState,
  createInitialPackMappingState,
  EVEN_PACKS,
} from '../reducers/editConstants';
import { MappingSet, PackCalculator } from '../services/types';

export const createStateFromMappingSet = (data: MappingSet): MappingSetState => ({
  ...createInitialPackMappingSetState(),
  name: data.name,
  mappings: map(data.mappings, mapping => ({
    ...createInitialPackMappingState(),
    skus: mapping.skus,
    attributeModelUrl: mapping._links.attributeModel?.href ?? '',
    packCalculatorUrl: mapping._links.packCalculator.href,
    retrievedPackCalculatorId: mapping._embedded.packCalculator.id,
  })),
  etag: data.etag,
});

export const createStateFromPackCalculator = (data: PackCalculator): PackCalculatorState => ({
  id: data.id,
  name: data.name,
  inputs: data.inputs,
  preferredPackQuantity: String(data.preferredPackQuantity ?? ''),
  packableQuantities: data.packableQuantities?.join(','),
  maxQuantity: data.maxQuantity?.toString() ?? '',
  shreddingStrategy: data.shreddingStrategy ?? (data.packableQuantities ? BIGGEST_PACKS : EVEN_PACKS),
  packsPerContainer: data.packsPerContainer,
  preferredContainerType: data.preferredContainerType,
  weightFormula: data.weight?.value ?? '',
  weightUnit: data.weight?.unit ?? '',
  lengthFormula: data.length?.value ?? '',
  widthFormula: data.width?.value ?? '',
  heightFormula: data.height?.value ?? '',
  diameterFormula: data.diameter?.value ?? '',
  // Whichever combination of dimensions they use should all be same unit
  dimensionUnit: (data.length?.unit || data.width?.unit || data.height?.unit || data.diameter?.unit) ?? '',
  etag: data.etag,
});
