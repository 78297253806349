import React from 'react';

import FourOhFour from '@cimpress/react-components/lib/FourOhFour';

const PageNotFound = () => (
  <div style={{ paddingTop: '100px' }}>
    <FourOhFour />
  </div>
);

export default PageNotFound;
