import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import messages from './messages';

const WeightUnitSelect = ({ defaultWeight, ...props }) => {
  const { formatMessage } = useIntl();

  const weightUnitOptions = [
    { label: 'mg', value: 'mg' },
    { label: 'g', value: 'g' },
    { label: 'kg', value: 'kg' },
    { label: 'oz', value: 'oz' },
    { label: 'lb', value: 'lb' },
  ];

  const defaultSelection = weightUnitOptions.find(option => option.value === defaultWeight);

  return (
    <Select
      label={formatMessage(messages.unit)}
      options={weightUnitOptions}
      {...props}
      value={props.value ?? defaultSelection}
    />
  );
};

export default WeightUnitSelect;
