import { useQuery, UseQueryOptions } from 'react-query';

import { ServiceHttpResponseError } from '../services/serviceHelpers';
import { getPackMappingSet } from '../services/shippablePackDetails';
import { MappingSet } from '../services/types';

const usePackMappingSet = ({
  id,
  ...rest
}: { id: string | undefined } & UseQueryOptions<MappingSet, ServiceHttpResponseError, MappingSet, any[]>) => {
  return useQuery(['packMappingSet', id], () => getPackMappingSet(id!), {
    ...rest,
    enabled: Boolean(id),
  });
};

export default usePackMappingSet;
