import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Accordion from '@cimpress/react-components/lib/Accordion';
import Button from '@cimpress/react-components/lib/Button';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Drawer from '@cimpress/react-components/lib/Drawer';

import messages from './messages';

const PackFilterDrawer = ({ allAccounts, allUsers, showDrawer, setShowDrawer, filter, setFilter }) => {
  const { formatMessage } = useIntl();

  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);

  const onApplyFilters = () => {
    setFilter({ ...filter, accounts, users });
    setShowDrawer(false);
  };

  const onClearAll = () => {
    setAccounts([]);
    setUsers([]);
  };

  const onAccountCheckboxChange = (_, payload) => {
    const { checked, accountId } = payload;

    const newAccounts = checked ? accounts.filter(a => a !== accountId) : uniq([...accounts, accountId]);

    setAccounts(newAccounts);
  };

  const onUserCheckboxChange = (_, payload) => {
    const { checked, user } = payload;

    const newUsers = checked
      ? users.filter(u => u !== user.canonical_principal)
      : uniq([...users, user.canonical_principal]);

    setUsers(newUsers);
  };

  const footer = (
    <div>
      <Button variant="default" onClick={onClearAll}>
        {formatMessage(messages.clearAll)}
      </Button>
      <Button variant="primary" onClick={onApplyFilters}>
        {formatMessage(messages.apply)}
      </Button>
    </div>
  );

  return (
    <Drawer
      show={showDrawer}
      onRequestHide={onApplyFilters}
      header={<h1>{formatMessage(messages.advancedFilters)}</h1>}
      closeOnClickOutside
      footer={footer}
    >
      <Accordion title={formatMessage(messages.accountsLabel)} bodyStyle={{ padding: 0 }}>
        {allAccounts.map(account => {
          const checked = accounts.includes(account.accountId);
          const payload = { checked, accountId: account.accountId };
          return (
            <Checkbox
              key={account.accountId}
              label={account.name}
              checked={checked}
              payload={payload}
              onChange={onAccountCheckboxChange}
              style={{ padding: '6px 16px' }}
            />
          );
        })}
      </Accordion>
      <Accordion title={formatMessage(messages.usersLabel)} bodyStyle={{ padding: 0 }}>
        {orderBy(allUsers, 'name').map(user => {
          const checked = users.includes(user.canonical_principal);
          const payload = { checked, user };
          const label = `${user.name} (${user.canonical_principal})` ?? user.canonical_principal;
          return (
            <Checkbox
              key={user.canonical_principal}
              label={label}
              checked={checked}
              payload={payload}
              onChange={onUserCheckboxChange}
              style={{ padding: '6px 16px' }}
            />
          );
        })}
      </Accordion>
    </Drawer>
  );
};

export default PackFilterDrawer;
