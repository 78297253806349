import React from 'react';

import { useAccount } from '../../hooks/useAccounts';

const AccountLink = ({ accountId }) => {
  const { data: account, isLoading } = useAccount({ accountId });

  const displayName = isLoading || !account?.name ? accountId : account.name;

  return (
    <a href={`https://accounts.cimpress.io/${accountId}`} target="_blank" rel="noopener noreferrer">
      {displayName}
    </a>
  );
};

export default AccountLink;
