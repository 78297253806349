import React from 'react';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { ReactTablev6 } from '@cimpress/react-components';

import AccountLink from './AccountLink';
import UserLink from './UserLink';
import messages from './messages';

const PackMappingSetTable = ({ packMappingSets, loading, ...props }) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      Header: formatMessage(messages.name),
      accessor: 'name',
      Cell: ({ original, value }) => <Link to={`/views/${original.id}`}>{value}</Link>,
    },
    {
      Header: formatMessage(messages.account),
      accessor: 'accountId',
      Cell: ({ value }) => <AccountLink accountId={value} />,
    },
    {
      Header: formatMessage(messages.createdBy),
      accessor: 'createdBy',
      Cell: ({ value }) => <UserLink principal={value} />,
    },
    {
      Header: formatMessage(messages.createdAt),
      accessor: 'createdAt',
      Cell: ({ value }) => (
        <Moment format="MMMM DD, YYYY h:mm A" local>
          {value}
        </Moment>
      ),
    },
    {
      Header: formatMessage(messages.modifiedBy),
      accessor: 'modifiedBy',
      Cell: ({ value }) => (value ? <UserLink principal={value} /> : formatMessage(messages.notModified)),
    },
    {
      Header: formatMessage(messages.modifiedAt),
      accessor: 'modifiedAt',
      Cell: ({ value }) =>
        value ? (
          <Moment format="MMMM DD, YYYY h:mm A" local>
            {value}
          </Moment>
        ) : (
          formatMessage(messages.notModified)
        ),
    },
  ];

  return (
    <ReactTablev6
      columns={columns}
      data={packMappingSets}
      loading={loading}
      loadingText={formatMessage(messages.loading)}
      noDataText={formatMessage(messages.noData)}
      {...props}
    />
  );
};

export default PackMappingSetTable;
