import { useQuery } from 'react-query';

import { getPackMappingSets } from '../services/shippablePackDetails';

const usePackMappingSets = ({
  accounts,
  creators,
  limit,
  offset,
}: {
  accounts?: string[];
  creators?: string[];
  limit?: number;
  offset?: number;
}) => {
  return useQuery(
    ['packMappingSets', { accounts, creators, limit, offset }],
    () => getPackMappingSets(accounts, creators, limit, offset),
    {
      retry: 3,
    },
  );
};

export default usePackMappingSets;
