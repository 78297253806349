import styled from '@emotion/styled';
import * as JsSearch from 'js-search';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import IconFilterAlt from '@cimpress-technology/react-streamline-icons/lib/IconFilterAlt';
import Button from '@cimpress/react-components/lib/Button';
import Card from '@cimpress/react-components/lib/Card';
import { ocean } from '@cimpress/react-components/lib/colors';

import auth from '../../auth';
import { useAccounts } from '../../hooks/useAccounts';
import usePackMappingSets from '../../hooks/usePackMappingSets';
import { useUsersWithPermission } from '../../hooks/useUser';
import PackTable from '../PackTable';
import PackFilterDrawer from './PackFilterDrawer';
import PackSearch from './PackSearch';
import messages from './messages';

const AdvancedFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  color: ${ocean.base};
  cursor: pointer;

  & > svg {
    margin-right: 3px;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const CardContainer = styled(Card)`
  max-width: 85%;
  margin: auto;
  margin-top: 30px;
`;

const HomeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleContainer = styled.h1`
  font-weight: 330;
`;

const filterMappingSets = (mappingSets, searchQuery, filter) => {
  const search = new JsSearch.Search('id');
  search.addIndex('id');
  search.addIndex('name');
  search.addDocuments(mappingSets);
  const filterdTableData = searchQuery.trim() !== '' ? search.search(searchQuery) : mappingSets;

  return filterdTableData.filter(mappingSet => {
    const filterByAccount = filter.accounts.length === 0 || filter.accounts.includes(mappingSet.accountId);
    const filterByCreatedBy = filter.users.length === 0 || filter.users.includes(mappingSet.createdBy);
    const filterByModifiedBy = filter.users.length === 0 || filter.users.includes(mappingSet.modifiedBy);
    return filterByAccount && (filterByCreatedBy || filterByModifiedBy);
  });
};

const HomePage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const [filter, setFilter] = useState({
    accounts: [],
    users: [],
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);

  const { data, isLoading } = usePackMappingSets({
    limit: 200,
    offset: 0,
  });

  const { data: accounts } = useAccounts();

  const mappingSets = data?.mappingSets ?? [];

  const userAccountId = auth.getProfile()['https://claims.cimpress.io/account'];
  const { data: users } = useUsersWithPermission({
    resourceType: 'account',
    resourceIdentifier: userAccountId,
    permission: 'create:calculator',
  });

  const numberOfFilters = filter.users.length + filter.accounts.length;

  const tableData = filterMappingSets(mappingSets, searchQuery, filter);

  return (
    <CardContainer>
      <HomeContainer>
        <TitleContainer>{formatMessage(messages.packs)}</TitleContainer>
        <Button variant="primary" size="lg" onClick={() => history.push('/create')}>
          {formatMessage(messages.createPack)}
        </Button>
      </HomeContainer>
      <PackFilterDrawer
        allAccounts={accounts ?? []}
        allUsers={users ?? []}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        filter={filter}
        setFilter={setFilter}
      />
      <AdvancedFilterContainer onClick={() => setShowDrawer(true)}>
        <IconFilterAlt weight="fill" />
        {formatMessage(messages.advancedFilters) + ` ${numberOfFilters > 0 ? `(${numberOfFilters})` : ''}`}
      </AdvancedFilterContainer>
      <PackSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <PackTable packMappingSets={tableData} loading={isLoading} />
    </CardContainer>
  );
};

export default HomePage;
