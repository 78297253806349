import React from 'react';
import { useIntl } from 'react-intl';

import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';
import Button from '@cimpress/react-components/lib/Button';
import TextField from '@cimpress/react-components/lib/TextField';

import messages from './messages';

const PackSearch = ({ searchQuery, setSearchQuery }) => {
  const { formatMessage } = useIntl();

  return (
    <TextField
      value={searchQuery}
      label={formatMessage(messages.searchLabel)}
      onChange={e => setSearchQuery(e.target.value)}
      rightAddon={
        <Button variant="primary">
          <IconSearch style={{ paddingRight: 5 }} />
          {formatMessage(messages.search)}
        </Button>
      }
    />
  );
};

export default PackSearch;
