import * as FullStory from '@fullstory/browser';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

FullStory.init({ orgId: '97CT5' });

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}
