import { useQuery } from 'react-query';

import fetchWithAuth from '../services/fetchWithAuth';

const useAttributeModel = ({ attributeModelUrl, onSuccess }: { attributeModelUrl: string; onSuccess?: any }) => {
  return useQuery(
    ['useAttributeModel', { attributeModelUrl }],
    () => fetchWithAuth({ endpointUrl: attributeModelUrl }),
    {
      retry: 3,
      enabled: Boolean(attributeModelUrl),
      onSuccess,
    },
  );
};

export default useAttributeModel;
