import { AttributeModelState, MappingSetState, PackCalculatorState } from '../components/shared/types';

type RemovePackMapping = { mappingId: string };
type EditMappingSet = { field: string; value: string };
type EditMapping = { mappingId: string; field: string; value: string | string[] };
type EditPackCalculator = { mappingId: string; field: string; value: string };
type RehydrateAttributeModel = { mappingId: string; data: AttributeModelState };

export type Action =
  | { type: 'ADD_PACK_MAPPING' }
  | { type: 'REMOVE_PACK_MAPPING'; payload: RemovePackMapping }
  | { type: 'EDIT_MAPPING_SET'; payload: EditMappingSet }
  | { type: 'EDIT_MAPPING'; payload: EditMapping }
  | { type: 'EDIT_PACK_CALCULATOR'; payload: EditPackCalculator }
  | { type: 'REHYDRATE_MAPPING_SET'; payload: MappingSetState }
  | { type: 'REHYDRATE_PACK_CALCULATOR'; payload: PackCalculatorState }
  | { type: 'REHYDRATE_ATTRIBUTE_MODEL'; payload: RehydrateAttributeModel };

export const addPackMapping = () => ({ type: 'ADD_PACK_MAPPING' as const });
export const removePackMapping = (payload: RemovePackMapping) => ({ type: 'REMOVE_PACK_MAPPING' as const, payload });
export const editMappingSet = (payload: EditMappingSet) => ({ type: 'EDIT_MAPPING_SET' as const, payload });
export const editMapping = (payload: EditMapping) => ({ type: 'EDIT_MAPPING' as const, payload });
export const editPackCalculator = (payload: EditPackCalculator) => ({ type: 'EDIT_PACK_CALCULATOR' as const, payload });

export const rehydrateAttributeModel = (payload: RehydrateAttributeModel) => ({
  type: 'REHYDRATE_ATTRIBUTE_MODEL' as const,
  payload,
});
export const rehydrateMappingSet = (payload: MappingSetState) => ({ type: 'REHYDRATE_MAPPING_SET' as const, payload });
export const rehydratePackCalculator = (payload: PackCalculatorState) => ({
  type: 'REHYDRATE_PACK_CALCULATOR' as const,
  payload,
});
