import { defineMessages } from 'react-intl';

export default defineMessages({
  account: {
    id: 'packTable.account',
    defaultMessage: 'Account',
  },
  createdAt: {
    id: 'packTable.createdAt',
    defaultMessage: 'Created At',
  },
  createdBy: {
    id: 'packTable.createdBy',
    defaultMessage: 'Created By',
  },
  loading: {
    id: 'packTable.loading',
    defaultMessage: 'Loading packs...',
  },
  modifiedAt: {
    id: 'packTable.modifiedAt',
    defaultMessage: 'Modified At',
  },
  modifiedBy: {
    id: 'packTable.modifiedBy',
    defaultMessage: 'Modified By',
  },
  name: {
    id: 'packTable.packName',
    defaultMessage: 'Name',
  },
  noData: {
    id: 'packTable.noData',
    defaultMessage: 'No packs found.',
  },
  notModified: {
    id: 'packTable.notModified',
    defaultMessage: 'Not Modified',
  },
});
