import { find } from 'lodash';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import messages from './messages';

const PreferredPackQuantitySelect = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      {...props}
      label={formatMessage(messages.preferredPackQuantity)}
      options={props.options}
      value={find(props.options, option => option.value === props.value)}
    />
  );
};

export default PreferredPackQuantitySelect;
