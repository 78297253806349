import { useEffect, useState } from 'react';

import { fetchContext, filterPayloads } from '@cimpress-technology/unified-cimpress-navigator';

import auth from '../auth';
import { FcData } from '../components/shared/types';

export const useUcnContext = () => {
  // ucn states
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ucnId, setUcnId] = useState<string>();
  const [originFcUrl, setOriginFcUrl] = useState<string>();
  const [fcData, setFcData] = useState<FcData>();

  useEffect(() => {
    const fetchUcnContext = async () => {
      const accessToken = auth.getAccessToken();
      const { context: ucnContext, error: ucnError } = await fetchContext({ accessToken });

      // NOTE: breaking the context chain due to ucn related error is unfortunate, but is not a hard stop
      // for now, exposing these errors may confuse the users more than helping
      // we are swallowing ucn related errors for now.
      // Once we start getting more traction, this could be displayed as a warning visually different
      // from our typical errors
      if (ucnContext && !ucnError) {
        // set the ucnId
        const { contextId, payloads } = ucnContext;
        setUcnId(contextId);

        const fcUIUrl = process.env.REACT_APP_FULFILLMENT_CONFIGURATION_UI_URL;
        const packManagerUrl = process.env.REACT_APP_BASE_URL;

        // grab the latest payload that navigated from FC -> FDO
        const {
          payloads: [latestPayload],
        } = filterPayloads({
          payloads,
          originHostname: fcUIUrl,
          destinationHostname: packManagerUrl,
        });

        // get the fc related data
        setOriginFcUrl(latestPayload?.connection?.origin);
        setFcData(latestPayload?.body?.fulfillmentConfiguration);
      }

      setIsLoading(false);
    };

    fetchUcnContext();
  }, []);

  // NOTE: This returns the accounts data, and also creates a map of accountUrl => accountName
  // and exposes a function that takes in a url and gives you the account name,
  // which we can use in both the table view and the expanded fdo view to display the account name.
  return {
    isLoading,
    ucnId,
    originFcUrl,
    fcData,
  };
};
