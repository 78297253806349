import PropTypes from 'prop-types';
import React from 'react';

import Robot from '@cimpress/react-components/lib/shapes/Robot';

const ErrorDisplay = ({ errorMsg }) => (
  <div className="robot" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Robot status="warning" />
    <p>
      {errorMsg} Please contact <a href="mailto:platformsupport@cimpress.com">platformsupport@cimpress.com</a> for
      support.
    </p>
  </div>
);

ErrorDisplay.propTypes = {
  errorMsg: PropTypes.string.isRequired,
};

export default ErrorDisplay;
