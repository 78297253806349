import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import messages from './messages';

const PackContainerSelect = ({ defaultContainer, ...props }) => {
  const { formatMessage } = useIntl();

  const containerOptions = [
    { label: formatMessage(messages.box), value: 'box' },
    { label: formatMessage(messages.tube), value: 'tube' },
    { label: formatMessage(messages.envelope), value: 'envelope' },
    { label: formatMessage(messages.pallet), value: 'pallet' },
  ];

  const defaultSelection = containerOptions.find(option => option.value === defaultContainer);

  return (
    <Select
      label={formatMessage(messages.preferredContainerType)}
      options={containerOptions}
      {...props}
      value={props.value ?? defaultSelection}
    />
  );
};

export default PackContainerSelect;
