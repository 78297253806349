import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import auth from './auth';
import CreatePage from './components/CreatePage';
import HomePage from './components/HomePage';
import Loading from './components/Loading';
import PageNotFound from './components/404';
import Shell from './components/Shell';
import { IntlProvider } from 'react-intl';
import translations, { DEFAULT_LANGUAGE } from './i18n/locales/index';
import ViewPage from './components/ViewPage';

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));

class App extends Component {
  state = {
    authenticating: false,
    authenticationError: undefined,
    language: DEFAULT_LANGUAGE, // TODO: load settings from language selector once we support more languages
  };

  constructor(props) {
    super(props);

    const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
    this.history = createBrowserHistory({ basename });
  }

  componentDidMount() {
    if (!auth.isLoggedIn()) {
      this.setState({ authenticating: true });
      auth
        .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
        .then(() => {
          this.setState({ authenticating: false });
        })
        .catch(err => {
          this.setState({ authenticationError: err });
        });
    }
  }

  render() {
    const { authenticating, authenticationError, language } = this.state;
    const messages = translations[language];

    return (
      <div>
        {authenticating ? (
          <Loading />
        ) : auth.isLoggedIn() ? (
          <IntlProvider locale={language} key={language} messages={messages}>
            <Router history={this.history}>
              <Shell>
                <Switch>
                  <Route exact path="/(index.html)?" component={HomePage} />
                  <Route path="/create" exact component={CreatePage} />
                  <Route path="/views/:id" component={ViewPage} />
                  <Route path="/edit/:id" component={CreatePage} />
                  <Route component={PageNotFound} />
                </Switch>
              </Shell>
            </Router>
          </IntlProvider>
        ) : authenticationError ? (
          <div>Unexpected error encountered. {authenticationError.message}</div>
        ) : null}
      </div>
    );
  }
}

export default App;
