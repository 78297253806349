import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { SnackBarContext } from '../components/shared/snackbarContext';
import { deletePackMappingSet } from '../services/shippablePackDetails';

const useDeletePackMappingSet = ({ onSuccess, ...rest }) => {
  const queryClient = useQueryClient();
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const onErrorWithSnackbar = error => {
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error deleting the pack mapping set.' });
    rest.onError && rest.onError(error);
  };

  const onSuccessWithSnackbar = (data, variables) => {
    queryClient.removeQueries(['packMappingSet', variables.id], { exact: true });
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'success', details: 'Successfully deleted the pack mapping set.' });
    onSuccess && onSuccess(data);
  };

  return useMutation(deletePackMappingSet, {
    ...rest,
    onSuccess: onSuccessWithSnackbar,
    onError: onErrorWithSnackbar,
  });
};

export default useDeletePackMappingSet;
