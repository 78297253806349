import React from 'react';

import TextField from '@cimpress/react-components/lib/TextField';

type FormulaDisplayProps = {
  label: string;
  value?: string;
  unit?: string;
};

const FormulaDisplay = ({ label, value, unit }: FormulaDisplayProps) => {
  if (!value) {
    return null;
  }

  const formula = unit ? `${value} (${unit})` : value;

  // NOTE: look into scrolling when the text overflows
  return <TextField label={label} readOnly value={formula} onChange={val => val} />;
};

export default FormulaDisplay;
