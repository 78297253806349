import React from 'react';

import { useUser } from '../../hooks/useUser';

const UserLink = ({ principal }) => {
  const { data: user, isLoading } = useUser({ principal });

  const displayName = isLoading || !user?.profile?.name ? principal : user.profile.name;

  return (
    <a href={`https://users.cimpress.io/users/${principal}`} target="_blank" rel="noopener noreferrer">
      {displayName}
    </a>
  );
};

export default UserLink;
