import { defineMessages } from 'react-intl';

export default defineMessages({
  account: {
    id: 'viewPage.account',
    defaultMessage: 'Account',
  },
  attributeModel: {
    id: 'viewPage.attributeModel',
    defaultMessage: 'Attribute Model',
  },
  createdAt: {
    id: 'viewPage.createdAt',
    defaultMessage: 'Created At',
  },
  createdBy: {
    id: 'viewPage.createdBy',
    defaultMessage: 'Created By',
  },
  delete: {
    id: 'viewPage.delete',
    defaultMessage: 'Delete',
  },
  areYouSure: {
    id: 'viewPage.areYouSure',
    defaultMessage: 'Are you sure you want to delete this?',
  },
  cancel: {
    id: 'viewPage.cancel',
    defaultMessage: 'Cancel',
  },
  diameterFormula: {
    id: 'viewPage.diameterFormula',
    defaultMessage: 'Diameter Formula',
  },
  dividePacksEvenly: {
    id: 'createPage.dividePacksEvenly',
    defaultMessage: 'Divide Packs Evenly',
  },
  edit: {
    id: 'viewPage.edit',
    defaultMessage: 'Edit',
  },
  errorMessage: {
    id: 'viewPage.errorMessage',
    defaultMessage: 'Error retrieving pack information.',
  },
  heightFormula: {
    id: 'viewPage.heightFormula',
    defaultMessage: 'Height Formula',
  },
  lengthFormula: {
    id: 'viewPage.lengthFormula',
    defaultMessage: 'Length Formula',
  },
  maxQuantity: {
    id: 'viewPage.maxQuantity',
    defaultMessage: 'Maximum Quantity',
  },
  modifiedAt: {
    id: 'viewPage.modifiedAt',
    defaultMessage: 'Modified At',
  },
  modifiedBy: {
    id: 'viewPage.modifiedBy',
    defaultMessage: 'Modified By',
  },
  name: {
    id: 'viewPage.name',
    defaultMessage: 'Name',
  },
  packFormulas: {
    id: 'viewPage.packFormulas',
    defaultMessage: 'Pack Formulas',
  },
  packsPerContainer: {
    id: 'viewPage.packsPerContainer',
    defaultMessage: 'Packs Per Container',
  },
  preferredContainerType: {
    id: 'viewPage.preferredContainerType',
    defaultMessage: 'Preferred Container Type',
  },
  productInputs: {
    id: 'viewPage.productInputs',
    defaultMessage: 'Product Inputs',
  },
  quantities: {
    id: 'viewPage.quantities',
    defaultMessage: 'Quantities',
  },
  scope: {
    id: 'viewPage.scope',
    defaultMessage: 'Scope',
  },
  skus: {
    id: 'viewPage.skus',
    defaultMessage: 'SKUs',
  },
  useBiggestPacks: {
    id: 'createPage.useBiggestPacks',
    defaultMessage: 'Use Biggest Packs',
  },
  volumeFormula: {
    id: 'viewPage.volumeFormula',
    defaultMessage: 'Volume Formula',
  },
  weightFormula: {
    id: 'viewPage.weightFormula',
    defaultMessage: 'Weight Formula',
  },
  widthFormula: {
    id: 'viewPage.widthFormula',
    defaultMessage: 'Width Formula',
  },
  preferredPackQuantity: {
    id: 'viewPage.preferredPackQuantity',
    defaultMessage: 'Preferred Pack Quantity',
  },
});
