import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal } from '@cimpress/react-components';

import messages from './messages';

const DeleteButton = ({ onConfirmDelete }: { onConfirmDelete: () => void }) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button size="lg" style={{ marginLeft: '10px' }} onClick={() => setShowModal(true)}>
        {formatMessage(messages.delete)}
      </Button>
      <Modal
        title={formatMessage(messages.delete)}
        footer={
          <>
            <Button onClick={() => setShowModal(false)}>{formatMessage(messages.cancel)}</Button>
            <Button variant="primary" onClick={onConfirmDelete}>
              {formatMessage(messages.delete)}
            </Button>
          </>
        }
        show={showModal}
      >
        {formatMessage(messages.areYouSure)}
      </Modal>
    </>
  );
};
export default DeleteButton;
