import find from 'lodash/find';
import isNil from 'lodash/isNil';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import auth from '../../auth';
import { useAccounts } from '../../hooks/useAccounts';
import { Account } from '../../services/types';
import { SnackBarContext } from '../shared/snackbarContext';
import messages from './messages';

const AccountSelect = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const setDefaultAccount = (accounts: Account[]) => {
    const userAccountId = auth.getProfile()['https://claims.cimpress.io/account'];
    const userAccount = find(accounts, account => account.accountId === userAccountId);
    if (isNil(props.value) && userAccount) {
      props.onChange({ label: userAccount.name, value: userAccount.accountId });
    }
  };

  const showAccountFetchError = () => {
    setShowSnackbar(true);
    setSnackbarDetails({
      type: 'danger',
      details: formatMessage(messages.accountFetchFailed),
    });
  };

  const { isLoading, data: accounts } = useAccounts({
    onSuccess: setDefaultAccount,
    onError: showAccountFetchError,
    retry: 3,
  });

  const options =
    accounts?.map(account => {
      return { label: account.name, value: account.accountId };
    }) ?? [];

  if (isLoading) {
    return <Select label={formatMessage(messages.loading)} isDisabled={true} />;
  }

  return (
    <Select
      {...props}
      label={formatMessage(messages.accountSelect)}
      options={options}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default AccountSelect;
