import buildUrl from 'build-url';
import get from 'lodash/get';

import { fetchWithAuth } from './fetchWithAuth';
import { FulfillmentConfiguration } from './types';

const endpointUrl = process.env.REACT_APP_FULFILLMENT_CONFIGURATION_SEARCH as string;

export const searchFulfillmentConfigurations = async (
  searchQuery: string,
): Promise<{ fulfillmentConfigurations: FulfillmentConfiguration[]; total: number }> => {
  const queryParams: any = {
    searchQuery,
  };

  const route = buildUrl('v1/fulfillmentConfigurationVersions', {
    queryParams: queryParams,
    disableCSV: true,
  });

  const response = await fetchWithAuth({ endpointUrl, route });
  const fulfillmentConfigurations = get(response, 'results', []);

  return { fulfillmentConfigurations, total: response.total };
};
