import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { SnackBarContext } from '../components/shared/snackbarContext';
import { createPackMappingSet } from '../services/shippablePackDetails';

const useNewPackMappingSet = properties => {
  const queryClient = useQueryClient();
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const onErrorWithSnackbar = () => {
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error creating the pack mapping set.' });
  };

  return useMutation(createPackMappingSet, {
    ...properties,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['packMappingSet', data.id], data);
      properties.onSuccess && properties.onSuccess(data, variables);
    },
    onError: onErrorWithSnackbar,
  });
};

export default useNewPackMappingSet;
