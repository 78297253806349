/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import IconArrowLeftAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowLeftAlt';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import { navigate } from '@cimpress-technology/unified-cimpress-navigator';
import { Button, Card } from '@cimpress/react-components';

import auth from '../../auth';
import { useAccount } from '../../hooks/useAccounts';
import useDeletePackMappingSet from '../../hooks/useDeletePackMappingSet';
import usePackMappingSet from '../../hooks/usePackMappingSet';
import { useUcnContext } from '../../hooks/useUcnContext';
import { MappingSet } from '../../services/types';
import Loading from '../Loading';
import UserLink from '../PackTable/UserLink';
import ErrorDisplay from '../shared/ErrorDisplay';
import { MatchProps } from '../shared/types';
import DeleteButton from './DeleteButton';
import PackMappingViewer from './PackMappingViewer';
import MappingProps from './mappingProps';
import messages from './messages';

const metadataDisplayStyle = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
`;

const ViewPage = ({ match }: MatchProps) => {
  const { id } = match.params;
  const { formatMessage } = useIntl();
  const history = useHistory();

  const packMappingSetQuery = usePackMappingSet({ id });
  const accountDataQuery = useAccount({
    accountId: packMappingSetQuery.data?.accountId,
  });

  const { mutate: deletePackMappingSet } = useDeletePackMappingSet({
    onSuccess: () => triggerNavigation({ ucnId, originFcUrl, deleted: true, packMappingSetData }),
  });

  const { isLoading: isLoadingUcnContext, ucnId, originFcUrl } = useUcnContext();

  const onConfirmDelete = () => {
    deletePackMappingSet({ id, etag: packMappingSetQuery.data!.etag });
  };

  const backButton = (
    <Button
      style={{ marginBottom: '10px' }}
      size="sm"
      onClick={() => triggerNavigation({ ucnId, originFcUrl, packMappingSetData })}
    >
      <IconArrowLeftAlt weight="fill" style={{ height: '8px' }} /> {ucnId && originFcUrl ? 'Return to FC UI' : 'Home'}
    </Button>
  );

  if (packMappingSetQuery.isError || accountDataQuery.isError) {
    return (
      <div style={{ margin: 'auto', marginTop: '30px' }}>
        {backButton}
        <ErrorDisplay errorMsg={formatMessage(messages.errorMessage)} />
      </div>
    );
  }

  if (
    packMappingSetQuery.isLoading ||
    packMappingSetQuery.isIdle ||
    accountDataQuery.isLoading ||
    accountDataQuery.isIdle ||
    isLoadingUcnContext
  ) {
    return <Loading />;
  }

  const packMappingSetData = packMappingSetQuery.data;

  // if ucn id is present, persist it to edit page
  const ucnIdQuery = ucnId ? `?ucnId=${ucnId}` : '';
  const toEditScreen = () => {
    history.push(`/edit/${packMappingSetData.id}${ucnIdQuery}`);
  };

  const triggerNavigation = async ({
    ucnId,
    packMappingSetData,
    originFcUrl,
    deleted = false,
  }: {
    ucnId?: string;
    packMappingSetData?: MappingSet;
    originFcUrl?: string;
    deleted?: boolean;
  }) => {
    if (ucnId && originFcUrl) {
      if (packMappingSetData) {
        const payload = {
          packMappingSet: {
            id: packMappingSetData.id,
            name: packMappingSetData.name,
            isDeleted: deleted,
            _links: {
              self: {
                href: packMappingSetData._links.self.href,
              },
            },
          },
        };

        const { destination, error } = await navigate({
          userData: payload,
          destination: originFcUrl,
          accessToken: auth.getAccessToken(),
        });

        // if there was a ucn error, still navigate back to FC UI, just not persisting the context
        if (!error) {
          window.location.assign(destination);
        }
      }
      window.location.assign(originFcUrl);
    } else {
      history.push('/');
    }
  };

  return (
    <div className="container">
      {backButton}
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>{packMappingSetData.name}</h1>
          <div>
            <Button size="lg" variant="primary" onClick={toEditScreen}>
              <IconPencilAlt weight="fill" style={{ height: '15px' }} />
              <span style={{ marginLeft: '5px' }}>{formatMessage(messages.edit)}</span>
            </Button>
            <DeleteButton onConfirmDelete={onConfirmDelete} />
          </div>
        </div>
        <hr />
        <div css={metadataDisplayStyle}>
          <div>
            <h5>{formatMessage(messages.account)}</h5>
            <p>{accountDataQuery.data.name}</p>
          </div>
          <div>
            <h5>{formatMessage(messages.createdBy)}</h5>
            <UserLink principal={packMappingSetData.createdBy} />
            <div style={{ marginTop: '20px' }}>
              <h5>{formatMessage(messages.createdAt)}</h5>
              <Moment format="MMMM DD, YYYY h:mm A" local>
                {packMappingSetData.createdAt}
              </Moment>
            </div>
          </div>
          <div>
            {packMappingSetData.modifiedBy && (
              <div>
                <h5>{formatMessage(messages.modifiedBy)}</h5>
                <UserLink principal={packMappingSetData.modifiedBy} />
              </div>
            )}
            {packMappingSetData.modifiedAt && (
              <div style={{ marginTop: '20px' }}>
                <h5>{formatMessage(messages.modifiedAt)}</h5>
                <Moment format="MMMM DD, YYYY h:mm A" local>
                  {packMappingSetData.modifiedAt}
                </Moment>
              </div>
            )}
          </div>
        </div>
        {packMappingSetData.mappings.map((mapping: MappingProps, idx: number) => {
          return <PackMappingViewer key={idx} mapping={mapping} />;
        })}
      </Card>
    </div>
  );
};

export default ViewPage;
