import { useQuery } from 'react-query';

import { getUser, getUsers, getUsersWithPermission } from '../services/coam';

export const useUser = ({ principal }) => {
  return useQuery(['user', { principal }], () => getUser(principal), {
    retry: 3,
  });
};

export const useUsers = ({ principals }) => {
  return useQuery(['users', principals], () => getUsers(principals), {
    retry: false,
  });
};

export const useUsersWithPermission = ({ resourceType, resourceIdentifier, permission }) => {
  return useQuery(
    ['usersWithPermission', { resourceType, resourceIdentifier, permission }],
    () => getUsersWithPermission(resourceType, resourceIdentifier, permission),
    {
      retry: false,
    },
  );
};
