import React from 'react';

import Button from '@cimpress/react-components/lib/Button';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const SaveButton = ({ text, savingText, isSaving, ...props }) => {
  const saving = (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: '5px' }}>
        <Spinner size="small" />
      </div>
      <div>{savingText}</div>
    </div>
  );

  return (
    <Button {...props} disabled={props.disabled || isSaving}>
      {isSaving ? saving : text}
    </Button>
  );
};

export default SaveButton;
