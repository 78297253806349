import produce from 'immer';
import set from 'lodash/set';

import { MappingSetState } from '../components/shared/types';
import { Action } from './editActions';
import { createInitialPackMappingState } from './editConstants';

const editReducer = produce((draft: MappingSetState, action: Action) => {
  switch (action.type) {
    case 'REHYDRATE_MAPPING_SET': {
      return action.payload;
    }

    case 'REHYDRATE_PACK_CALCULATOR': {
      const mapping = draft.mappings.find(mapping => mapping.retrievedPackCalculatorId === action.payload.id);
      mapping!.packCalculator = action.payload;

      return draft;
    }

    case 'REHYDRATE_ATTRIBUTE_MODEL': {
      const mapping = draft.mappings.find(mapping => mapping.id === action.payload.mappingId);
      mapping!.attributeModel = action.payload.data;

      return draft;
    }

    case 'ADD_PACK_MAPPING': {
      draft.mappings.push(createInitialPackMappingState());
      return draft;
    }

    case 'REMOVE_PACK_MAPPING': {
      const { mappingId } = action.payload;
      const index = draft.mappings.findIndex(mapping => mapping.id === mappingId);
      draft.mappings.splice(index, 1);
      return draft;
    }

    case 'EDIT_MAPPING_SET': {
      const { field, value } = action.payload;
      set(draft, field, value);
      return draft;
    }

    case 'EDIT_MAPPING': {
      const { mappingId, field, value } = action.payload;
      const mapping = draft.mappings.find(mapping => mapping.id === mappingId);
      set(mapping!, field, value);
      return draft;
    }

    case 'EDIT_PACK_CALCULATOR': {
      const { mappingId, field, value } = action.payload;
      const mapping = draft.mappings.find(mapping => mapping.id === mappingId);
      set(mapping!.packCalculator, field, value);
      return draft;
    }

    default: {
      return draft;
    }
  }
});

export default editReducer;
