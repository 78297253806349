import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { Input, MappingSetState, PackCalculatorState } from '../components/shared/types';
import { BIGGEST_PACKS, EVEN_PACKS } from '../reducers/editConstants';
import { ShreddingStrategy } from '../services/types';

type Formula = {
  value: string;
  unit: string;
};
type DimensionFormulas = {
  height?: Formula;
  diameter?: Formula;
  length?: Formula;
  width?: Formula;
  weight?: Formula;
};
export type PackCalculatorRequest = DimensionFormulas & {
  name: string;
  accountId: string;
  inputs: Input[];
  packableQuantities?: number[];
  maxQuantity?: number;
  minQuantity?: number;
  quantityIncrement?: number;
  shreddingStrategy?: ShreddingStrategy;
  preferredContainerType: string;
  packsPerContainer: string;
  etag?: string;
  coamGroupId?: string;
};

export type TestPackCalculatorRequest = {
  key: string;
  skus: string[];
  _links?: { attributeModel: { href: string } };
  _embedded?: { packCalculator: PackCalculatorRequest };
};

export type TestPackCalculatorMappingSet = {
  accountId: string;
  mappings: TestPackCalculatorRequest[];
};

export const createTestEvaluateRequestFromState = (
  accountId: string,
  mappingSetState: MappingSetState,
): TestPackCalculatorMappingSet => {
  const mappings = mappingSetState.mappings.map(mapping => {
    const packCalculatorState = mapping.packCalculator;
    const formulas = createFormulas(packCalculatorState);

    const packableQuantities =
      packCalculatorState.packableQuantities
        ?.split(',')
        .filter(quantity => quantity !== '')
        .map(Number) ?? [];

    const preferredPackQuantity = packCalculatorState.preferredPackQuantity
      ? Number(packCalculatorState.preferredPackQuantity)
      : undefined;

    const packCalculator: PackCalculatorRequest = removeNullFields({
      name: packCalculatorState.name,
      accountId,
      inputs: packCalculatorState.inputs,
      packableQuantities,
      preferredPackQuantity,
      preferredContainerType: packCalculatorState.preferredContainerType,
      packsPerContainer: packCalculatorState.packsPerContainer,
      etag: packCalculatorState.etag,
      ...formulas,
    });

    if (!isEmpty(packCalculatorState.packableQuantities)) {
      packCalculator.packableQuantities = packCalculatorState.packableQuantities?.split(',').map(Number);
      packCalculator.shreddingStrategy = BIGGEST_PACKS;
    }

    if (!isEmpty(packCalculatorState.maxQuantity)) {
      packCalculator.maxQuantity = Number(packCalculatorState.maxQuantity);
      packCalculator.minQuantity = 1;
      packCalculator.quantityIncrement = 1;
      packCalculator.shreddingStrategy = EVEN_PACKS;
    }

    const testPackCalculatorRequest: TestPackCalculatorRequest = {
      key: mapping.id,
      skus: mapping.skus,
      _embedded: { packCalculator },
    };
    if (mapping.attributeModelUrl) {
      testPackCalculatorRequest._links = { attributeModel: { href: mapping.attributeModelUrl } };
    }
    return testPackCalculatorRequest;
  });
  return { accountId, mappings };
};

export const createMappingSetRequestFromState = ({
  accountId,
  mappingSetState,
}: {
  accountId: string;
  mappingSetState: MappingSetState;
}) => {
  const mappings = mappingSetState.mappings.map(mapping => {
    const packCalculatorState = mapping.packCalculator;
    const formulas = createFormulas(packCalculatorState);

    const packableQuantities =
      packCalculatorState.packableQuantities
        ?.split(',')
        .filter(quantity => quantity !== '')
        .map(Number) ?? [];

    const preferredPackQuantity = packCalculatorState.preferredPackQuantity
      ? Number(packCalculatorState.preferredPackQuantity)
      : undefined;

    const packCalculator: PackCalculatorRequest = removeNullFields({
      name: packCalculatorState.name,
      accountId,
      inputs: packCalculatorState.inputs,
      packableQuantities,
      preferredPackQuantity,
      preferredContainerType: packCalculatorState.preferredContainerType,
      packsPerContainer: packCalculatorState.packsPerContainer,
      etag: packCalculatorState.etag,
      ...formulas,
    });

    if (!isEmpty(packCalculatorState.packableQuantities)) {
      packCalculator.packableQuantities = packCalculatorState.packableQuantities?.split(',').map(Number);
      packCalculator.shreddingStrategy = BIGGEST_PACKS;
    }

    if (!isEmpty(packCalculatorState.maxQuantity)) {
      packCalculator.maxQuantity = Number(packCalculatorState.maxQuantity);
      packCalculator.minQuantity = 1;
      packCalculator.quantityIncrement = 1;
      packCalculator.shreddingStrategy = EVEN_PACKS;
    }

    return {
      skus: mapping.skus,
      packCalculator,
      attributeModelUrl: mapping.attributeModelUrl,
      packCalculatorUrl: mapping.packCalculatorUrl,
      packCalculatorId: mapping.retrievedPackCalculatorId,
    };
  });

  return {
    name: mappingSetState.name,
    mappings,
    etag: mappingSetState.etag,
  };
};

const createFormulas = (packCalculatorState: PackCalculatorState) => {
  const formulas: DimensionFormulas = {};

  if (packCalculatorState.preferredContainerType === 'tube') {
    if (packCalculatorState.heightFormula) {
      formulas.height = {
        value: packCalculatorState.heightFormula,
        unit: packCalculatorState.dimensionUnit,
      };
    }

    if (packCalculatorState.diameterFormula) {
      formulas.diameter = {
        value: packCalculatorState.diameterFormula,
        unit: packCalculatorState.dimensionUnit,
      };
    }
  } else {
    if (packCalculatorState.lengthFormula) {
      formulas.length = {
        value: packCalculatorState.lengthFormula,
        unit: packCalculatorState.dimensionUnit,
      };
    }

    if (packCalculatorState.widthFormula) {
      formulas.width = {
        value: packCalculatorState.widthFormula,
        unit: packCalculatorState.dimensionUnit,
      };
    }

    if (packCalculatorState.heightFormula) {
      formulas.height = {
        value: packCalculatorState.heightFormula,
        unit: packCalculatorState.dimensionUnit,
      };
    }
  }

  if (packCalculatorState.weightFormula) {
    formulas.weight = {
      value: packCalculatorState.weightFormula,
      unit: packCalculatorState.weightUnit,
    };
  }

  return formulas;
};

const removeNullFields = <T>(obj: T): T => omitBy(obj, isNil) as T;
