import { map as pMap } from 'bluebird';
import buildUrl from 'build-url';
import toString from 'lodash/toString';

import { getAccount } from './accounts';
import { fetchWithAuth } from './fetchWithAuth';

const coamUrl = process.env.REACT_APP_COAM_URL as string;

export const getUser = async (principal: string) => {
  return await fetchWithAuth({
    endpointUrl: coamUrl,
    route: `auth/access-management/v1/principals/${principal}?include=false&responseFilter=groups,permissions`,
  });
};

export const getUsers = async (principals: string[]) => {
  return pMap(
    principals,
    async principal => {
      return await fetchWithAuth({
        endpointUrl: coamUrl,
        route: `auth/access-management/v1/principals/${principal}?include=false&responseFilter=groups,permissions`,
      });
    },
    { concurrency: 8 },
  );
};

export const getUsersWithPermission = async (resourceType: string, resourceIdentifier: string, permission: string) => {
  const endpointUrl = buildUrl(coamUrl, {
    path: 'auth/access-management/v1/search/canonicalPrincipals/byPermission',
    queryParams: {
      resource_type: resourceType,
      resource_identifier: resourceIdentifier,
      permission,
    },
  });

  const { canonical_principals } = await fetchWithAuth({ endpointUrl });

  return canonical_principals
    .filter(
      (principal: { is_client: boolean; profiles: Record<string, any>[] }) =>
        !principal.is_client && principal.profiles.length > 0,
    )
    .map((principal: { profiles: { name: string }[] }) => {
      return { ...principal, name: principal.profiles[0].name };
    });
};

export const fetchGroupId = async (accountId: string) => {
  const account = await getAccount(accountId);
  return toString(account.essentialGroupId);
};

export const fetchGroups = async ({ resourceId }: { resourceId: string }) => {
  const response = await fetchWithAuth({
    endpointUrl: coamUrl,
    route: `auth/access-management/v1/groups?resource_type=pack-mapping-set&resource_identifier=${resourceId}`,
  });
  return response?.groups;
};
