import { useQuery, UseQueryOptions } from 'react-query';

import { getAccount, getAccountByUrl, getAccounts } from '../services/accounts';
import { ServiceHttpResponseError } from '../services/serviceHelpers';
import { Account } from '../services/types';

export const useAccount = ({ accountId }: { accountId: string | undefined }) => {
  return useQuery(['account', { accountId }], () => getAccount(accountId!), {
    enabled: Boolean(accountId),
    retry: 3,
  });
};

export const useAccountByUrl = ({
  accountUrl,
  ...rest
}: { accountUrl: string | undefined } & UseQueryOptions<Account, ServiceHttpResponseError, Account, any[]>) => {
  return useQuery(['account', { accountUrl }], () => getAccountByUrl(accountUrl!), {
    ...rest,
    enabled: Boolean(accountUrl),
    retry: 3,
  });
};

export const useAccounts = (parameters: UseQueryOptions<Account[], ServiceHttpResponseError, Account[], string[]>) => {
  return useQuery(['accounts'], () => getAccounts(), parameters);
};
