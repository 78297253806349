import orderBy from 'lodash/orderBy';

import { fetchWithAuth } from './fetchWithAuth';
import { Account, Accounts } from './types';

const accountUrl = process.env.REACT_APP_ACCOUNTS_URL as string;

export const getAccount = async (accountId: string): Promise<Account> => {
  return (await fetchWithAuth({
    endpointUrl: accountUrl,
    route: `api/v1/accounts/${accountId}`,
  })) as Account;
};

export const getAccountByUrl = async (accountUrl: string): Promise<Account> => {
  return (await fetchWithAuth({
    endpointUrl: accountUrl,
  })) as Account;
};

export const getAccounts = async (): Promise<Account[]> => {
  const response = (await fetchWithAuth({
    endpointUrl: accountUrl,
    route: 'api/v1/accounts?status=Active&fields=name',
  })) as Accounts;

  return orderBy(response!._embedded.item, 'name');
};
