import compact from 'lodash/compact';

import { MappingSetState, MappingState } from '../components/shared/types';
import { EVEN_PACKS } from '../reducers/editConstants';

export const validateMappingSet = ({
  mappingSet,
  accountId,
}: {
  mappingSet: MappingSetState;
  accountId: string | undefined;
}) => {
  const hasName = Boolean(mappingSet.name.trim());
  const hasAccount = Boolean(accountId);
  const hasMappings = mappingSet.mappings.length !== 0;
  const mappingsAreValid = mappingSet.mappings.every((mapping: MappingState) => {
    const { packCalculator } = mapping;
    const hasName = Boolean(packCalculator.name);

    const hasPreferredContainerType = Boolean(packCalculator.preferredContainerType);
    const hasAttributeModelUrl = Boolean(mapping.attributeModelUrl);
    const hasSkus = compact(mapping.skus).length > 0;
    const isUsingEvenSplitStrategy = packCalculator.shreddingStrategy === EVEN_PACKS;
    const packableQuantities =
      packCalculator.packableQuantities
        ?.split(',')
        .filter(value => value !== '')
        .map(Number) ?? [];

    const preferredPackQuantity = Number(packCalculator.preferredPackQuantity);

    const packableQuantitiesValid = packableQuantities.length !== 0 && packableQuantities.every(value => value > 0);

    const preferredPackQuantityValid =
      !packCalculator.preferredPackQuantity || new Set(packableQuantities).has(preferredPackQuantity);

    const maxQuantityValid = Boolean(packCalculator.maxQuantity);

    const hasValidQuantities = isUsingEvenSplitStrategy ? maxQuantityValid : packableQuantitiesValid;

    // NOTE: The current limitation with this validation is that we do not allow the user to save at all if there were any errors fetching an attribute model,
    // even if the user tries to re-save the lost data or modifies it.
    // This is because the attribute model component does not expose the internal state of the component, so there is no way for us to know.
    // While annoying, I felt that this was the safest way to ensure that the user does not lose existing data
    const attributeModelFetchSuccessful = hasAttributeModelUrl && Boolean(mapping.attributeModel);

    return (
      hasName &&
      hasPreferredContainerType &&
      (hasAttributeModelUrl || hasSkus) &&
      preferredPackQuantityValid &&
      hasValidQuantities &&
      (!hasAttributeModelUrl || attributeModelFetchSuccessful)
    );
  });

  return hasName && hasAccount && hasMappings && mappingsAreValid;
};
