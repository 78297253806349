import { defineMessages } from 'react-intl';

export default defineMessages({
  advancedFilters: {
    id: 'homePage.advancedFilters',
    defaultMessage: 'Advanced Filters',
  },
  createPack: {
    id: 'homePage.createPack',
    defaultMessage: 'Create Pack',
  },
  packs: {
    id: 'homePage.packs',
    defaultMessage: 'Packs',
  },
  search: {
    id: 'homePage.search',
    defaultMessage: 'Search',
  },
  searchLabel: {
    id: 'homePage.searchLabel',
    defaultMessage: 'Search by name or identifier',
  },
  accountsLabel: {
    id: 'homePage.accountsLabel',
    defaultMessage: 'Accounts',
  },
  usersLabel: {
    id: 'homePage.usersLabel',
    defaultMessage: 'Users',
  },
  apply: {
    id: 'homePage.apply',
    defaultMessage: 'Apply',
  },
  clearAll: {
    id: 'homePage.clearAll',
    defaultMessage: 'Clear All',
  },
});
