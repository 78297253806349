import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from 'react-query';

import { testEvaluate } from '../services/shippablePackDetails';

const useTestEvaluation = () => {
  const testEvaluationResult = useMutation(testEvaluate);
  const { data, error: serviceError } = testEvaluationResult;

  let error;
  if (serviceError) {
    error = serviceError;
  }
  let caveats = get(data, '_embedded.item[0].caveats');

  if (!isEmpty(caveats)) {
    error = caveats;
  }

  return { ...testEvaluationResult, data: error ? undefined : data, error };
};

export default useTestEvaluation;
