import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { SnackBarContext } from '../components/shared/snackbarContext';
import { ServiceHttpResponseError } from '../services/serviceHelpers';
import { updatePackMappingSet } from '../services/shippablePackDetails';

// TODO: Fix typing for useMutation hooks
const useUpdatePackMappingSet = (properties: any) => {
  const queryClient = useQueryClient();
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const onErrorWithSnackbar = (error: ServiceHttpResponseError) => {
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error updating the pack mapping set.' });
    properties.onError && properties.onError(error);
  };

  return useMutation(updatePackMappingSet, {
    ...properties,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['packMappingSet', variables.packMappingSetId], data);
      properties.onSuccess && properties.onSuccess(data, variables);
    },
    onError: onErrorWithSnackbar,
  });
};

export default useUpdatePackMappingSet;
