import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@cimpress/react-components/lib/Select';

import messages from './messages';

const DimensionUnitSelect = ({ defaultUnit, ...props }) => {
  const { formatMessage } = useIntl();

  const dimensionUnitOptions = [
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'm', value: 'm' },
    { label: 'in', value: 'in' },
    { label: 'ft', value: 'ft' },
  ];

  const defaultSelection = dimensionUnitOptions.find(option => option.value === defaultUnit);

  return (
    <Select
      label={formatMessage(messages.unit)}
      options={dimensionUnitOptions}
      {...props}
      value={props.value ?? defaultSelection}
    />
  );
};

export default DimensionUnitSelect;
