import { defineMessages } from 'react-intl';

export default defineMessages({
  appName: {
    id: 'appName',
    defaultMessage: 'Pack Manager',
  },
  header: {
    id: 'homePage.header',
    defaultMessage: 'View Packs',
  },
  home: {
    id: 'homePage.home',
    defaultMessage: 'Home',
  },
});
