import { useQuery, UseQueryOptions } from 'react-query';

import { searchFulfillmentConfigurations } from '../services/fulfillmentConfigurationSearch';
import { ServiceHttpResponseError } from '../services/serviceHelpers';
import { FulfillmentConfigurationSearchResponse } from '../services/types';

const useFulfillmentConfigurationSearch = ({
  id,
  ...rest
}: { id: string | undefined } & UseQueryOptions<
  FulfillmentConfigurationSearchResponse,
  ServiceHttpResponseError,
  FulfillmentConfigurationSearchResponse,
  any[]
>) => {
  return useQuery(['fulfillmentConfigurationsForPackMappingSet', id], () => searchFulfillmentConfigurations(id!), {
    ...rest,
    enabled: Boolean(id),
  });
};

export default useFulfillmentConfigurationSearch;
