import isNil from 'lodash/isNil';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { SnackBarContext } from '../components/shared/snackbarContext';
import { fetchGroupId, fetchGroups } from '../services/coam';

export const useGroupId = (accountId: string) => {
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const onError = () => {
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error fetching COAM group.' });
  };

  return useQuery(['groupId', accountId], () => fetchGroupId(accountId), {
    retry: false,
    enabled: !isNil(accountId),
    onError,
  });
};

export const useGroups = ({ resourceId }: { resourceId: string }) => {
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const onError = () => {
    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error fetching COAM groups.' });
  };

  return useQuery(['coamGroups', resourceId], () => fetchGroups({ resourceId }), {
    retry: false,
    enabled: !isNil(resourceId),
    onError,
  });
};
