import { buildOptions, checkResponse } from './serviceHelpers';

type FetchWithAuth = {
  endpointUrl: string;
  method?: string;
  route?: string;
  body?: object;
  additionalHeaders?: object;
  giveSimpleResponse?: boolean;
};

// eslint-disable-next-line no-undef
export const fetchWithAuth = async ({
  endpointUrl,
  method = 'GET',
  route,
  body,
  additionalHeaders = {},
  giveSimpleResponse = true,
}: FetchWithAuth) => {
  const options = buildOptions(method, body, additionalHeaders);
  const fullUrl = route ? `${endpointUrl}/${route}` : endpointUrl;

  try {
    const rawResponse = await fetch(fullUrl, options);
    return await checkResponse(rawResponse, `${method} ${fullUrl}`, giveSimpleResponse);
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
